const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_NAVIGATION': {
		const { payload } = action;
		const { name, list } = payload;
		return { ...state, navigation: { ...state.navigation, [name]: list } };
	}

	default:
		return state;
	}
};

export default reducer;
