import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/auction';

const AuctionContext = createContext();
export default AuctionContext;

const getToday = () => {
	const date = new Date();
	return `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`;
};

const getCustomDate = (numDays, direction) => {
	const date = new Date();
	date.setDate(date.getDate() + numDays * (typeof direction === 'undefined' || direction === '+' ? 1 : -1));

	return `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`;
};

export function AuctionProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		// Saved, subscriptions, ... actions
		saved: null, // if user has item at list of saved (multiple instances of component @ detail)

		// API data collector
		apiDataCollector: {},

		// Graphs data collector
		graphDataCollectorAllCnt: 3, // etnMarket (ETN), etnAuction (ETN), listingMarket (MK)
		graphDataCollector: {},

		etnFilter: {
			date_from: getCustomDate('731', '-'), // last 2 years
			date_to: getToday()
		},
		etnFilterTriggered: 0, // remote trigger, to refetch ETN data (on every increase)

		// Modal
		modal: null
	});

	return (<AuctionContext.Provider value={{ data, dispatch }}>{children}</AuctionContext.Provider>);
}

AuctionProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(AuctionContext);
