import React from 'react';

import styles from './loader.module.scss';

const LoaderLine = React.memo(() => {
	return (
		<div className={styles.line} />
	);
});

export default LoaderLine;
