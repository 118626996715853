import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import useAuth from '../_hooks/useAuth';
import useJWT from '../_hooks/useJWT';
import useUser from '../_hooks/useUser';

const StateWatcher = () => {
	const router = useRouter();
	const { userDispatch } = useUser();
	const { events, asPath } = router;

	// == Page change watcher
	const { logout } = useAuth();
	const { isJwtTokenValidClient, isJwtTokenValidServer } = useJWT();
	const [lastRoute, setLastRoute] = useState(asPath); // default = home

	const handleRouteChange = (route) => {
		if (route !== lastRoute) setLastRoute(route);
	};

	// Client token check
	useEffect(() => {
		if (isJwtTokenValidClient() === false) {
			logout();
		} else {
			// Init checking user token on server (handled in useJWT, if isJwtTokenValidServer == null)
			if (isJwtTokenValidServer !== null) {
				userDispatch({ type: 'SET_IS_JWT_TOKEN_VALID_SERVER', payload: null })
			}
		}
	}, [lastRoute]);

	// Server token check
	useEffect(() => {
		if (isJwtTokenValidServer === false) {
			logout();
		}
	}, [isJwtTokenValidServer]);

	useEffect(() => {
		events.on('routeChangeComplete', handleRouteChange);
		return () => events.off('routeChangeComplete', handleRouteChange);
	}, [events]);

	// == Page change watcher END

	return null;
};

export default StateWatcher;
