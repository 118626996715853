import { useState } from 'react';
import { useRouter } from 'next/router';

import { useStore as auctionStore } from '../../contexts/auction';
import useGQLQuery from './useGQLQuery';

const useAuction = (requestName, options) => {
	const { query } = useRouter();
	const { data: auctionData, dispatch: auctionDispatch } = auctionStore();

	const getRequestName = (n, r) => {
		if (requestName && requestName.indexOf('|') !== -1) { // ex. auction>items|auctions-new
			const parts = requestName.split('|');
			if (r === 'request_path') return parts[0]; // ex. auction>items

			return parts[1]; // ex. auctions-new
		}

		return n;
	};

	// Get auction list
	const { loading, result, error, setVariables } = useGQLQuery(getRequestName(requestName, 'request_nm'), getRequestName(requestName, 'request_path'), {}, { enabled: false, ...options });

	// Get auction id from url
	const getAuctionIdFromUrl = () => {
		if (query.urisegment) {
			const urisegmentParts = query.urisegment.split('-');

			if (urisegmentParts.length > 0) {
				return urisegmentParts[urisegmentParts.length - 1];
			}
		}

		return null;
	};

	// == Auction application

	// Is auction application editable

	const isAuctionApplicationEditable = (d) => {
		return (!d.auction_application_id || (d && ['10', '90'].includes(d.status_id))); // 10 = Draft, 90 = Rejected
	};

	// Formaters

	const numberFormat = (number, decimals, decPoint, thousandsSep) => {
		const no = number.replace(/[^0-9+\-Ee.]/g, '');

		const num = !isFinite(+no) ? 0 : +no;
		const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
		const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
		const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;

		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		const x = prec ? toFixedFix(num, prec) : Math.round(num);
		const s = x.toString().split('.');

		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}

		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}

		return s.join(dec);
	};

	const formatPrice = (v) => {
		if (v !== undefined && v && v !== '0' && v !== '') {
			let value = v.toString();

			// Set string, replace separator
			if (value.indexOf('.') !== -1) {
				value = replaceAll('.', '', value);
			}

			// Format and update in element
			const Formatted = numberFormat(value, 0, '.', '.');
			return Formatted;
		}

		return 0;
	};

	// Application, eAuction, Roles
	const [rolesProps] = useState({
		13: { minPersonCnt: 1, disclaimerChangeMinPersonCnt: 2 }, // 1 kontakt: prijavitelj sam tudi edini dražitelj; 1+ (n) kontakt: prijavitelj dražitelj v imenu in za račun več oseb– t.i. skupni pooblaščenec, pri čemer je tudi sam ena izmed teh oseb 
		14: { minPersonCnt: 2, disclaimerChangeMinPersonCnt: 3, roles: [{ id: '10', lngId: 'labelPersonRole10' }, { id: '11', lngId: 'labelPersonRole11' }] }, // 2 kontakta (min): prijavitelj na podlagi pooblastila pooblaščenec (enega) dražitelja; 2+ (n) kontakta: prijavitelj na podlagi pooblastil pooblaščenec vseh povezanih sodelujočih oseb, ki bodo skupaj dražile – t.i. skupni pooblaščenec, pri čemer sam ni ena izmed teh oseb, temveč tretja oseba 
		15: { minPersonCnt: 2, roles: [{ id: '20', lngId: 'labelPersonRole20' }, { id: '21', lngId: 'labelPersonRole21' }] }, // 2 kontakta (min): prijavitelj na podlagi zakona ali drugega pravnega akta zakoniti zastopniki pravne ali fizične osebe, ki bo nastopala kot dražitelj na javni dražbi oziroma jo bo on kot zakoniti zastopnik v tej vlogi na javni dražbi zastopal; 2+ (n) kontakta: enako
	});

	const getRoleProp = (propNm, auctionRoleId) => {
		if (rolesProps[auctionRoleId]) {
			return rolesProps[auctionRoleId][propNm] || null;
		}

		return null;
	};

	return {
		getAuctionIdFromUrl,
		auctionData,
		auctionDispatch,
		setVariables,
		loading,
		result,
		error,
		isAuctionApplicationEditable,
		formatPrice,
		rolesProps,
		getRoleProp
	};
};

export default useAuction;
