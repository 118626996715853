import React, { useEffect } from 'react';

import useNotification from '../_hooks/useNotification';
import useAuth from '../_hooks/useAuth';
import useGQLQuery from '../_hooks/useGQLQuery';

const StateLoader = React.memo(() => {
	const { authenticated, login } = useAuth();

	// Check user auth status (auto login)
	useEffect(() => login(), []);

	// == Posessid - create possess id and save it to localstorage, if not exist yet
	const { result, refetch: generateId } = useGQLQuery(null, 'session>generateid', {}, { enabled: false, refetch_vars_not_empty: true });

	useEffect(() => {
		if (result) window.localStorage.setItem('possessid', result.possessid);
	}, [result]);

	useEffect(() => {
		// Generate session id, if not exist
		if (!window.localStorage.getItem('possessid')) {
			console.log('GENERATE SESSION ID');
			generateId();
		}
	}, []);

	// Notifications (unread, header)
	const { loadNotifications } = useNotification('state-loader', false);

	useEffect(() => {
		if (authenticated) loadNotifications({ isread: 0 });
	}, [authenticated]);

	return null;
});

export default StateLoader;
