import { useState, useEffect } from 'react';

import useAuth from './useAuth';
import useApp from './useApp';
import useGQL from './useGQL';

const useNotification = (name, loadNotificationsFromServer) => {
	const { authenticated } = useAuth();
	const { appData: { notificationsUnreadList, notificationIsRead, notificationIsVisible }, appDispatch } = useApp();

	// console.log('useNotification rerender', name);

	// Query
	const [variables, setVariables] = useState({});

	const [result, setResult] = useState(null); // last result
	const [error, setError] = useState(null); // last error
	const { query } = useGQL('notification>items');
	const { isFetching, isSuccess, data, refetch } = query(variables, { enabled: loadNotificationsFromServer || false });

	useEffect(() => {
		if (isFetching === false && isSuccess === true) {
			setResult((data && data.request) ? data.request.list : []);
			setError(data.error ? data.message : null);
		}
	}, [isFetching]);

	useEffect(() => {
		if (result && !loadNotificationsFromServer) {
			appDispatch({ type: 'SET_NOTIFICATIONS_UNREAD', payload: result });
		}
	}, [result]);

	const loadNotifications = v => setVariables(v);

	useEffect(() => {
		if (authenticated && notificationsUnreadList === null && typeof variables.isread !== 'undefined') {
			console.log('refetch', name, variables);
			refetch();
		}
	}, [variables, authenticated]);

	// Mutation
	const { mutation: mutateNotification } = useGQL('notification>mutate');

	const setAsRead = (v, callback) => {
		if (authenticated) {
			mutateNotification({ ...v, isread: 1 }).then(({ request }) => {
				if (callback) callback(request, null);
			});
		}

		if (callback) callback(null);
	};

	// Other
	const setAllUnreadAsRead = (clearList = true) => {
		if (notificationsUnreadList && notificationsUnreadList.length > 0) {
			const l = [];
			notificationsUnreadList.map(row => l.push(row.log_notification_id));

			setAsRead({ log_notification_id: l }, (d, e) => {
				if (d) {
					appDispatch({ type: 'SET_NOTIFICATIONS_ISREAD' });

					if (clearList === true) {
						appDispatch({ type: 'SET_NOTIFICATIONS_UNREAD', payload: [] });
					}
				}
			});
		}
	};

	const toggleNotifications = () => appDispatch({ type: 'TOGGLE_NOTIFICATIONS' });

	return { loadNotifications, result, error, loading: isFetching, toggleNotifications, setAsRead, setAllUnreadAsRead, notificationsUnreadList, notificationIsRead, notificationIsVisible };
};

export default useNotification;
