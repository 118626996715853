import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';

import '../styles/main.scss';
import AppLoader from '../components/_system/app-loader';
import SkinLoader from '../components/_system/skin-loader';
import StateLoader from '../components/_system/state-loader';
import StateWatcher from '../components/_system/state-watcher';
import SystemMessages from '../components/_system/messages';
import LoaderLine from '../components/loader/loader-line';
import Providers from '../contexts/providers';

const MyApp = ({ Component, pageProps }) => {
	const [loadedCnt, incLoadedCnt] = useState(0);
	const [isBrowser] = useState(typeof window !== 'undefined');

	// Detect browsers/os
	const iOS = () => {
		if (isBrowser && window.navigator) {
			return [
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			].includes(window.navigator.platform) ||
			// iPad on iOS 13 detection
			(window.navigator.userAgent.includes('Mac') && 'ontouchend' in document);
		}

		return false;
	};

	const [isiOS, setIsiOS] = useState(false);
	const [isSafari, setIsSafari] = useState(false);

	useEffect(() => {
		// Clear html/md pages cache on refresh
		if (window.localStorage.getItem('content_pages') !== null) {
			window.localStorage.removeItem('content_pages');
		}
	}, []);

	// Calculate 1vh real height (and recalculate on scroll/resize)
	const handleResize = () => {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
	};

	useEffect(() => {
		if (isBrowser) {
			handleResize();
			window.addEventListener('resize', handleResize);

			// Check, if safari and/or iOS
			setIsiOS(iOS());
			setIsSafari(navigator.vendor.match(/apple/i));

			return () => window.removeEventListener('resize', () => handleResize);
		}

		return false;
	}, []);

	return (
		<QueryClientProvider client={new QueryClient()}>
			<Providers>
				<SkinLoader />
				<StateLoader />
				<StateWatcher />
				<SystemMessages />
				<div className={`${isSafari ? 'no-webp-img' : 'all-img'}${isiOS ? ' ios' : ''}`}>
					<AppLoader name="properties+language" onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ properties: true, language: true }} />
					<AppLoader name="coderegister" onLoad={() => incLoadedCnt(loadedCnt + 1)} load={{ coderegister: true }} />
					{ loadedCnt < 2 && <LoaderLine /> }
					<Component {...pageProps} />
				</div>
			</Providers>
		</QueryClientProvider>
	);
};

MyApp.propTypes = {
	// Component: PropTypes.func.isRequired,
	Component: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.node
	]).isRequired,
	pageProps: PropTypes.shape({}).isRequired
};

export default MyApp;
