const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_SAVED': {
		const { payload } = action;
		return { ...state, saved: payload };
	}

	case 'RESET_API_ETN_DATA': {
		return { ...state, apiDataCollector: { ...state.apiDataCollector, etnMarket: null, etnAuction: null } };
	}

	case 'ADD_API_DATA': {
		const { payload } = action;
		const { name, data } = payload;
		return { ...state, apiDataCollector: { ...state.apiDataCollector, [name]: data } };
	}

	case 'ADD_GRAPH_DATA': {
		const { payload } = action;
		const { name, data } = payload;
		return { ...state, graphDataCollector: { ...state.graphDataCollector, [name]: data } };
	}

	case 'SET_ETN_FILTER': {
		const { payload } = action;
		const { name, value } = payload;
		return { ...state, etnFilter: { ...state.etnFilter, [name]: value } };
	}

	case 'ETN_FILTER_TRIGGERED':
		return { ...state, etnFilterTriggered: state.etnFilterTriggered + 1 };

	case 'SET_MODAL': {
		const { payload } = action;
		return { ...state, modal: payload || null };
	}

	default:
		return state;
	}
};

export default reducer;
