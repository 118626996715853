import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from '../reducers/profile';

const ProfileContext = createContext();
export default ProfileContext;

export function ProfileProvider({ children }) {
	const [data, dispatch] = useReducer(reducer, {
		navigation: null
	});

	return (<ProfileContext.Provider value={{ data, dispatch }}>{children}</ProfileContext.Provider>);
}

ProfileProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useStore = () => useContext(ProfileContext);
