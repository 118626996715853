import { useEffect } from 'react';

import useConfig from '../_hooks/useConfig';

const SkinLoader = () => {
	const { clientConfig } = useConfig();

	// == Load SCSS variables
	useEffect(() => {
		// Images
		document.documentElement.style.setProperty('--skin-logo-positive', `url(${clientConfig('SKIN_LOGO_POSITIVE')})`);
		document.documentElement.style.setProperty('--skin-logo-positive-mobile', `url(${clientConfig('SKIN_LOGO_POSITIVE_MOBILE')})`);
		document.documentElement.style.setProperty('--skin-logo-negative', `url(${clientConfig('SKIN_LOGO_NEGATIVE')})`);
		document.documentElement.style.setProperty('--skin-logo-negative-mobile', `url(${clientConfig('SKIN_LOGO_NEGATIVE_MOBILE')})`);
		document.documentElement.style.setProperty('--skin-img-hero-webp', `url(${clientConfig('SKIN_IMG_HERO_WEBP')})`);
		document.documentElement.style.setProperty('--skin-img-hero-fallback', `url(${clientConfig('SKIN_IMG_HERO_FALLBACK')})`);

		// Colors
		document.documentElement.style.setProperty('--skin-color-primary', clientConfig('SKIN_COLOR_PRIMARY'));
		document.documentElement.style.setProperty('--skin-color-secondary', clientConfig('SKIN_COLOR_SECONDARY'));
		document.documentElement.style.setProperty('--skin-color-body', clientConfig('SKIN_COLOR_BODY'));
		document.documentElement.style.setProperty('--skin-color-error', clientConfig('SKIN_COLOR_ERROR'));
		document.documentElement.style.setProperty('--skin-color-success', clientConfig('SKIN_COLOR_SUCCESS'));

		// Background colors
		document.documentElement.style.setProperty('--skin-background-form', clientConfig('SKIN_BACKGROUND_FORM'));

		// Border
		document.documentElement.style.setProperty('--skin-border-color', clientConfig('SKIN_BORDER_COLOR'));

		// Font
		document.documentElement.style.setProperty('--skin-font-size-body', clientConfig('FONT_SIZE_BODY'));
		document.documentElement.style.setProperty('--skin-font-line-height-body', clientConfig('FONT_LINE_HEIGHT_BODY'));

		// Font family
		document.documentElement.style.setProperty('--skin-font-family-primary', clientConfig('SKIN_FONT_FAMILY_PRIMARY'));
		document.documentElement.style.setProperty('--skin-font-family-secondary', clientConfig('SKIN_FONT_FAMILY_SECONDARY'));
	}, []);

	return null;
};

export default SkinLoader;
