export const testMobile = () => { // is mobile or not
	return typeof window !== 'undefined' ? ((typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)) : false;
};

export function isEmptyObject(obj) {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}

// Find value in array of objects by key in object
export const findInAO = (list, key, matchValue) => {
	return list.find(row => row[key] === matchValue);
};

// Check, if object by field exist in array (ex. if object width id = 1 exist in array)
export const findObjectInArray = (list, key, value) => {
	let i;

	for (i = 0; i < list.length; i += 1) {
		if (list[i][key] === value) {
			return i; // return index of object in array
		}
	}

	return false;
};
